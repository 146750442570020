import React from "react";
import './403.less'
import { Button, Typography } from "antd";
import { Link } from 'gatsby';
import number from './../images/Error403Page.png';

const { Title, Paragraph } = Typography;

const forbiddenPage = () => {
  return(
    <main className="error-page-wrapper">
      <Title level={3} className="title-error-page">Error: 403 Forbidden</Title>
      <div className="number-wrapper">
        <img src={number}/>
      </div>
      <Paragraph className="error-page-text">Przepraszamy, nie masz uprawnień dostępu do tej strony.</Paragraph>
      <div className="button-wrapper">
        <Button className="button-error-page"><Link to='/' className='link-error-page'>Cofnij się</Link></Button>
      </div>
    </main>
  );
};

export default forbiddenPage;